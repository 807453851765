import React from 'react';


import LayoutMap from './LayoutMap';

const Layout = ({ match }) => {
    
    const { params: { layoutName } } = match;
  
    return (
        < LayoutMap name={layoutName}/>
    );
  };

  export default Layout;

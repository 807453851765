import React from 'react';
import {Route} from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
import style from '../../css/style.module.css';
// //Components
import Header from './header';
import Footer from './footer';

const MainLayoutRoute = ({component: Component, ...rest}) => {
    // const history = useHistory();
    // let bool = localStorage.getItem('x-access-token') && localStorage.getItem('x-access-token-expiration') > Date.now();
    // if(!bool){
    //     history.push('/login');
    // }else{
        return (
            <Route {...rest} render={matchProps => (
                <div className={style.body_wrapper}>
                    <Header/>
                    <Component {...matchProps}/>
                    <Footer/>
                </div>
            )}/>
        )
    // }
}

export default MainLayoutRoute;
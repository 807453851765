import React, { Component } from 'react';
import style from '../../../css/style.module.css';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

export default class MultiFilesUpload extends Component {
  
    constructor(props){
        super(props);
        this.state = {
            modal: false,
            modalEdit: false,
            send: false,
            albumName: '',
            albumId: '',
            imgCollection: '', 
            loaded: 0,
            photos: [],
            album: [],
            name: '',
            location: '',
            caption: '',
            date: '',
            cover_photo: '',
            selectedFile : null,
            oldName: '',
            redirectToHome: false
        }
    }   

    //fetch data after page loaded
    componentDidMount() {

        // let bool = localStorage.getItem('x-access-token') && localStorage.getItem('x-access-token-expiration') > Date.now();
        let bool = localStorage.getItem('x-access-token');
        if(bool){
            this.fetchData();
        }else{
            this.props.history.push('/login');
        }
    
    }

     //middlewares
     fetchData(){
        let thisAlbumName = this.props.name;
        axios.get(`https://api.bamboocopter.net/api/getAlbumByName/` + thisAlbumName)
        .then(res => {
          var album = res.data.album;
           this.setState({ 
                album,
                albumId : album[0]['id'],
                name: album[0]['name'],
                location: album[0]['location'],
                caption: album[0]['caption'],
                date: album[0]['date'],
                cover_photo: album[0]['cover_photo'],
            });

          axios.get(`https://api.bamboocopter.net/api/getPhotosByAlbumId/` + album[0]['id'])
            .then(res => {
                var photos = res.data.photos;
                console.log(photos);
                this.setState({ 
                    photos
                });
            });//end 2nd ajax
      }); //end first ajax
        
    }

    //on Uploading images
    submitAddForm(e){
        e.preventDefault();
        this.setState({modal: true});
        var formData = new FormData();
        for (let i = 0; i < this.state.imgCollection.length; i++) {
            formData.append(`photos[${i}]`, this.state.imgCollection[i])
        }
        formData.append('albumId', this.state.albumId);

        axios.post("https://api.bamboocopter.net/api/add-photo", formData, {
        onUploadProgress: ProgressEvent => {
            this.setState({
                loaded: (ProgressEvent.loaded / ProgressEvent.total*100),
                })
            },
            })
            .then(res => {
                console.log(res.statusText);
                this.setState({send: true, imgCollection: null});
                document.getElementById("addPhoto").reset();
            // this.setState({
            //     redirectToHome:true
            // })
        }) 
    }


    submitUpdateForm(e){
        e.preventDefault();
        this.setState({modal: true});
        const formData = new FormData();
        formData.append('photo', this.state.selectedFile);
        formData.append('name', this.state.name.toLowerCase());
        formData.append('location', this.state.location);
        formData.append('caption', this.state.caption);
        formData.append('date', this.state.date);

        axios.post("https://api.bamboocopter.net/api/updateAlbum/"+this.state.albumId, formData, {
            onUploadProgress: ProgressEvent => {
              this.setState({
                loaded: (ProgressEvent.loaded / ProgressEvent.total*100),
              })
            },
          })
        .then(res => {
            this.closeModal();
        }) 
    }


    //Delete the album
    deleteAlbum(){
        var letConfirm = window.confirm("Do you want to delete this photo?");
        if(letConfirm){
            console.log(this.props.name.toLowerCase());
            axios.post(`https://api.bamboocopter.net/api/turnOffAlbum/` + this.state.albumId)
            .then(res => {
                console.log(res.status);
                this.setState({
                    redirectToHome:true
                })
            });
        }
    }

    //Delete all photos, but keep the album
    deleteAllPhotos(){
        var letConfirm = window.confirm("Do you want to delete this photo?");
        if(letConfirm){
            axios.post(`https://api.bamboocopter.net/api/deleteAllPhotos/` + this.state.albumId)
            .then(res => {
                console.log(res.status);
                this.fetchData();
            });
        }
    }

    //delete one photo
    deleteOnePhoto(id){
        var letConfirm = window.confirm("Do you want to delete this photo?");
        if(letConfirm){
            axios.post(`https://api.bamboocopter.net/api/deletePhoto/`+id)
              .then(res => {
                console.log(res.status);
                this.fetchData();
            });
        }
    }

    showEditModal (){
        this.setState({modalEdit: true});
    }

    closeModal(){
        this.setState({modal: false, modalEdit: false});
        this.fetchData();
    }

   
    onNameChange(e){
        this.setState({name: e.target.value})
    }

    onLocationChange(e){
        this.setState({location: e.target.value})
    }
    onCaptionChange(e){
        this.setState({caption: e.target.value})
    }
    onDateChange(e){
    this.setState({createdDate: e.target.value})
    }
    resetForm(){
            this.setState({name: '', location: '', caption: ''});
    }
    onFileChange(e){
        this.setState({
            imgCollection: e.target.files,
            loaded: 0
        })
    }

    onFileChangeAlbum(e){
        this.setState({
          selectedFile: e.target.files[0],
          loaded: 0
        })
    }
    

    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
        [name]: value
        });
    }
    

    render(){

        if(this.state.redirectToHome){
            return <Redirect to = {{ pathname: "/" }} />;
        }else{
            return(
                <div className={style.albums}>
                    <div className={style.container}>
                        <div className={`${style.heading}`}>
                            <h1 id="txt-albumName">{this.props.name.split('-').join(' ').toUpperCase()}</h1>
                        </div>
    
                       
                        <hr/>
    
    
                        <form id="addPhoto" onSubmit={this.submitAddForm.bind(this)} method="POST" encType= "multipart/form-data">
                            <a className={`${style.title} ${style.btn}`} onClick={() => this.showEditModal()}>Edit Album</a>
                            <h2>Upload Images</h2>
                            <input type="file"  onChange={this.onFileChange.bind(this)} multiple/>
                            <br/>
                            <button className={`${style.btn}`}>Upload</button>
                          
                            <div className={`${style.btn_group}`}>
                                
                                <a className={`${style.title} ${style.btn} ${style.btn_delete_all}`} onClick={() => this.deleteAllPhotos()}>Delete All Photos</a>
    
                                <a className={`${style.title} ${style.btn} ${style.btn_delete_all}`} onClick={() => this.deleteAlbum()}>Delete Album</a>
                            </div>
                        </form>
    
                       
                       
                      
    
    
                        <hr/>
    
                        <div className={`${style.content}`}>
                        { this.state.photos.map(photo => 
                            <div key={photo.id} className={`${style.itemPhoto}`}>
                                <div className={`${style.bg}`} style={{backgroundImage: `url(${photo.url})`}}>
                                    <a className={`${style.btn_trash}`}onClick={() => this.deleteOnePhoto(photo.id)} ><i className="far fa-trash-alt"></i></a>
                                </div>
                            </div>
                        )}
                        </div>
                    </div>
                    <div className={style.modal} style={{display: this.state.modal ? 'flex' : 'none'}}>
                            <i className="fa fa-spinner fa-pulse fa-3x fa-fw" style={{color: '#fff', display: this.state.send ? 'none' : 'inline-block'}}></i><h1 style={{color: '#fff',textAlign: 'center', display: this.state.send ? 'none' : 'block-block'}}>{Math.round(this.state.loaded,2)}%</h1>
                    </div>
    
                    <div className={style.modal} style={{display: (this.state.send && this.state.modal) ? 'flex' : 'none'}}>
                        <div className={style.modal_content}>
                            <span className={style.modal_close} onClick={()=>this.closeModal()}><i className="fa fa-times" aria-hidden="true"></i></span>
                            
                            <p className={style.btn} onClick={()=>this.closeModal()}>{this.state.send ? 'Done.': ''}</p>
                        </div>
                    </div>


                    <div className={style.modal} style={{display: this.state.modalEdit ? 'flex' : 'none'}}>
                        { this.state.album.map(album => 
                            <form  key={album._id} className={style.update_form} id="update_form" onSubmit={this.submitUpdateForm.bind(this)} method="POST" encType= "multipart/form-data">
                                <span className={style.modal_close} onClick={()=>this.closeModal()}><i className="fa fa-times" aria-hidden="true"></i></span>
                                <h2>Edit details</h2>

                                <div className={style.row}>
                                    Name
                                    <input type="text" placeholder="Name" name="name" onChange={this.handleInputChange} value={this.state.name} required/>
                                </div>
                                <div className={style.row}>
                                    Location
                                    <input type="text" placeholder="Location" name="location" onChange={this.handleInputChange} value={this.state.location} required/>
                                </div>
                                <div className={style.row}>
                                    Caption
                                    <textarea placeholder="Caption" name="caption"  onChange={this.handleInputChange} value={this.state.caption}></textarea>
                                </div>
                                <div className={style.row}>
                                    Date
                                    <input type="text" placeholder="Date" name="date" onChange={this.handleInputChange} value={this.state.date} required/>
                                </div>
                                <div className={style.row}>
                                    <div className={`${style.bg}`} style={{backgroundImage: `url(${this.state.cover_photo})`}}>
                                    </div>
                                </div>
                                <div className={style.row}>
                                    <input type="file" onChange={this.onFileChangeAlbum.bind(this)}/>
                                </div>
                                
                                <div className={style.row}>
                                    <button type="submit" className={`${style.btn} ${style.btn_send}`}>Update</button>
                                </div>
                            </form>
                        )}
                    </div>


                </div>
            );
        }
        
    }
}
import React, { Component } from 'react';
// import { NavLink } from 'react-router-dom';
import style from '../../../css/style.module.css';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

export default class AddAlbumPage extends Component {
  
  constructor(props){
    super(props);
    this.state = {
        modal: false,
        send: false,
        name: '',
        location: '',
        caption: '',
        createdDate: '',
        selectedFile: null, 
        loaded: 0,
        redirectToHome: false
    }
}

 //Authentication
 componentDidMount() {

    // let bool = localStorage.getItem('x-access-token') && localStorage.getItem('x-access-token-expiration') > Date.now();
    let bool = localStorage.getItem('x-access-token');
    if(!bool){
        this.props.history.push('/login');
    }

}

submitAddForm(e){
    
    e.preventDefault();
    this.setState({modal: true});
    const location = document.getElementById('txt-location').value;    
    const createdDate = document.getElementById('txt-date').value;    
    const caption = document.getElementById('txt-caption').value;    
    const formData = new FormData();
    formData.append('photo', this.state.selectedFile);
    formData.append('name', this.state.name.toLowerCase());
    formData.append('location', location);
    formData.append('caption', caption);
    formData.append('date', createdDate);

    axios.post("https://api.bamboocopter.net/api/create-album/0", formData, {
      onUploadProgress: ProgressEvent => {
        this.setState({
          loaded: (ProgressEvent.loaded / ProgressEvent.total*100),
        })
      },
    })
    .then(res => {
        this.resetForm();
        console.log(res.statusText);
        this.setState({send: true, redirectToHome:true});
    }) 
  
}

resetForm(){
    document.getElementById("add_form").reset();
        this.setState({name: '', location: '', caption: '', selectedFile: null, loaded: 0});
}

onNameChange(e){
    this.setState({name: e.target.value})
}

onLocationChange(e){
    this.setState({location: e.target.value})
}
onCaptionChange(e){
    this.setState({caption: e.target.value})
}
onDateChange(e){
  this.setState({createdDate: e.target.value})
}

onFileChange(e){
  this.setState({
    selectedFile: e.target.files[0],
    loaded: 0
  })
}

closeModal(){
    this.setState({modal: false})
}

render(){

    if(this.state.redirectToHome){
        return <Redirect to = {{ pathname: "/" }} />;
    }else{
        return(
            <div className={`${style.create}`}>
            
                <div className={`${style.container}`}>
                    <div className={style.header_nav}>
                        <h1 className={style.headline}>
                        Add New Album
                        </h1>
                    </div>
                    <div className={`${style.form}`}>
                        <div className={style.card}>
                            <form id="add_form" onSubmit={this.submitAddForm.bind(this)} method="POST" encType= "multipart/form-data">
                                <div className={style.row}>
                                    <input type="text" placeholder="Name" id="txt-name" onChange={this.onNameChange.bind(this)} required/>
                                </div>
                                <div className={style.row}>
                                    <input type="text" placeholder="Location" id="txt-location" onChange={this.onLocationChange.bind(this)} required/>
                                </div>
                                <div className={style.row}>
                                    <input type="text" placeholder="Caption" id="txt-caption" onChange={this.onCaptionChange.bind(this)} required/>
                                </div>
                                <div className={style.row}>
                                    <input type="text" placeholder="Date" id="txt-date" onChange={this.onDateChange.bind(this)} required/>
                                </div>
                                <div className={style.row}>
                                    <input type="file" onChange={this.onFileChange.bind(this)} required/>
                                </div>
                                <div className={style.row}>
                                    <button type="submit" className={`${style.btn} ${style.btn_send}`}>Create</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className={style.modal} style={{display: this.state.modal ? 'flex' : 'none'}}>
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw" style={{color: '#fff', display: this.state.send ? 'none' : 'inline-block'}}></i><h1 style={{color: '#fff',textAlign: 'center', display: this.state.send ? 'none' : 'block-block'}}>{Math.round(this.state.loaded,2)}%</h1>
                </div>

                <div className={style.modal} style={{display: (this.state.send && this.state.modal) ? 'flex' : 'none'}}>
                    <div className={style.modal_content}>
                        <span className={style.modal_close} onClick={()=>this.closeModal()}><i className="fa fa-times" aria-hidden="true"></i></span>
                        
                        <p>{this.state.send ? 'A new record has been created': ''}</p>
                    </div>
                </div>
                
            </div> 
        );
    }
}
}
import React, { Component } from 'react';
import style from '../../../css/style.module.css';
import axios from 'axios';
import { NavLink } from 'react-router-dom';


export default class Layouts extends Component {
  
    constructor(props){
        super(props);
        this.state = {
            layouts: []
        }
      }
      
    
      componentDidMount() {

        // let bool = localStorage.getItem('x-access-token') && localStorage.getItem('x-access-token-expiration') > Date.now();
        let bool = localStorage.getItem('x-access-token');
        if(bool){
            axios.get(`https://api.bamboocopter.net/api/getLayouts`)
            .then(res => {
                const layouts = res.data.layouts;
                // console.log(layouts);
                this.setState({ 
                    layouts
                });
            })
        }else{
            this.props.history.push('/login');
        }
    
      }

    render(){
      
        return(
            <div className={style.albums}>
                
                <div className={style.container}>
                    <div className={`${style.content}`}>
                        { this.state.layouts.map(layout => 
                            <div key={layout.id} className={`${style.item}`}>
                                {/* <NavLink to={`/album/${album.name}`}> */}
                                <NavLink to={`/layout/${layout.slug}`}>
                                    <div className={`${style.bg} ${style.section}`}>{layout.name}</div>
                                </NavLink>
                            </div>
                        )}

                            <div className={`${style.item}`}>
                                <NavLink to={`/layout/create`}>
                                    <div className={`${style.bg} ${style.add}`}><span><i className="fas fa-plus icon fa-3x"></i></span></div>
                                    <h4 className={`${style.title}`}>Add New</h4>
                                </NavLink>
                            </div>
                    </div>
                </div>
            </div>
        );
    }
}
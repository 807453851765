import React from 'react';


import MultiFilesUpload from './multiFilesUpload';

const Album = ({ match }) => {
    
    const { params: { albumName } } = match;
  
    return (
        < MultiFilesUpload name={albumName}/>
    );
  };

  export default Album;

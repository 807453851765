import React, {Component} from 'react';
// import { NavLink } from 'react-router-dom';


//CSS
// import style from '../../css/style.module.css';

export default class Footer extends Component{
    render(){
        return (
            <footer>
            </footer>

        );
    }
}

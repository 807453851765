import React from 'react';


import CreateSectionMap from './CreateSectionMap';

const CreateSection = ({ match }) => {
    
    const { params: { layoutName } } = match;
  
    return (
        < CreateSectionMap name={layoutName}/>
    );
  };

  export default CreateSection;

import React, { Component } from 'react';
// import { NavLink } from 'react-router-dom';
import style from '../../../css/style.module.css';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

export default class LoginPage extends Component {
  
    constructor(props) {
        super(props)
        this.state = {
          email : '',
          password: '',
          authError: false
        };
      }


      handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
          [name]: value
        });
      }


      onSubmit = (event) => {
        event.preventDefault();

        axios.post('https://api.bamboocopter.net/api/login', {'email': this.state.email, 'password': this.state.password})
        .then(res => {
          if (res.data.user) {
            localStorage.setItem('x-access-token', res.data.token);
            localStorage.setItem('x-access-token-expiration', Date.now() + 2 * 60 * 60 * 1000);
            this.props.history.push('/');
            // if(localStorage.getItem('role') == 'super admin'){
            //   this.props.history.push('/admin-dashboard');
            // }else{
            //   this.props.history.push('/stores');
            // }
          } else {
            this.setState({authError : true});
            const error = new Error(res.error);
            throw error;
          }
        }).catch(err => {
          console.error(err);
          this.setState({authError : true});
          Promise.reject('Authentication Failed!');
        });
      }

      render() {
        return (
          <div className={style.login}>
            <form onSubmit={this.onSubmit}>
              <h1>Login</h1>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleInputChange}
                required
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={this.state.password}
                onChange={this.handleInputChange}
                required
              />
              <p style={{display: (this.state.authError) ? 'block' : 'none'}}>Invalid username or password!</p>
              
              
            <input type="submit" value="Submit" className={style.btn}/>
            </form>
          </div>   
        );
      }
}


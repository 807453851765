import React, { Component } from 'react';  
import { BrowserRouter as Router, Switch } from 'react-router-dom';  

/** Layouts **/  
import MainLayoutRoute from "./Components/Layouts/MainLayout"; 

// Pages
import Layouts from './Components/Pages/Layout/Layouts';
import Layout from './Components/Pages/Layout/Layout';
import CreateLayout from './Components/Pages/Layout/CreateLayout';
import CreateSection from './Components/Pages/Layout/CreateSection';
import Section from './Components/Pages/Layout/Section';


import Albums from './Components/Pages/Albums/Albums';
import Album from './Components/Pages/Albums/Album';
import CreateAlbum from './Components/Pages/Albums/Create';
import Register from './Components/Pages/Auth/Register';
import Login from './Components/Pages/Auth/Login';

export default class App extends Component {
  render(){
    return (
      <Router>  
        <Switch>  
          <MainLayoutRoute exact path="/" component={Albums} />  
          <MainLayoutRoute path="/layouts" component={Layouts} />  
          <MainLayoutRoute path="/layout/create" component={CreateLayout} /> 
          <MainLayoutRoute path="/layout/:layoutName" component={Layout} />  
          <MainLayoutRoute path="/section/:layoutSlug/:id" component={Section} />  
          <MainLayoutRoute path="/section-create/:layoutName" component={CreateSection} />  


          <MainLayoutRoute path="/album/create" component={CreateAlbum} /> 
          <MainLayoutRoute path="/album/:albumName" component={Album} />  
          <MainLayoutRoute path="/register" component={Register} />  
          <MainLayoutRoute path="/login" component={Login} />  
        </Switch>  
      </Router>  
    );
  }
}

import React, { Component } from 'react';
// import { NavLink } from 'react-router-dom';
import style from '../../../css/style.module.css';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

export default class CreateLayout extends Component {
  
  constructor(props){
    super(props);
    this.state = {
        modal: false,
        send: false,
        name: '',
        slug: '',
        loaded: 0,
        redirectToHome: false
    }
}

 //Authentication
 componentDidMount() {

    // let bool = localStorage.getItem('x-access-token') && localStorage.getItem('x-access-token-expiration') > Date.now();
    let bool = localStorage.getItem('x-access-token');
    if(!bool){
        this.props.history.push('/login');
    }

}

submitForm(e){
    
    e.preventDefault();
    axios.post('https://api.bamboocopter.net/api/createLayout', 
        {
            'name': this.state.name.toLowerCase(), 
            'slug': this.state.slug.toLowerCase()
        })
    .then(res => {
        this.resetForm();
        console.log(res.statusText);
        this.props.history.push('/layouts');
    }) 
  
}

resetForm(){
    document.getElementById("add_form").reset();
        this.setState({name: '', location: '', caption: '', selectedFile: null, loaded: 0});
}

handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
    [name]: value
    });
}

closeModal(){
    this.setState({modal: false})
}

render(){

    if(this.state.redirectToHome){
        return <Redirect to = {{ pathname: "/" }} />;
    }else{
        return(
            <div className={`${style.create}`}>
            
                <div className={`${style.container}`}>
                    <div className={style.header_nav}>
                        <h1 className={style.headline}>
                        Add New Layout
                        </h1>
                    </div>
                    <div className={`${style.form}`}>
                        <div className={style.card}>
                            <form id="add_form" onSubmit={this.submitForm.bind(this)} method="POST">
                                <div className={style.row}>
                                    <input
                                    type="text"
                                    name="name"
                                    placeholder="Name"
                                    value={this.state.name}
                                    onChange={this.handleInputChange}
                                    required
                                    />
                                </div>
                                <div className={style.row}>
                                    <input
                                    type="text"
                                    name="slug"
                                    placeholder="Slug"
                                    value={this.state.slug}
                                    onChange={this.handleInputChange}
                                    required
                                    />
                                </div>
                                <div className={style.row}>
                                    <button type="submit" className={`${style.btn} ${style.btn_send}`}>Create</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className={style.modal} style={{display: this.state.modal ? 'flex' : 'none'}}>
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw" style={{color: '#fff', display: this.state.send ? 'none' : 'inline-block'}}></i><h1 style={{color: '#fff',textAlign: 'center', display: this.state.send ? 'none' : 'block-block'}}>{Math.round(this.state.loaded,2)}%</h1>
                </div>

                <div className={style.modal} style={{display: (this.state.send && this.state.modal) ? 'flex' : 'none'}}>
                    <div className={style.modal_content}>
                        <span className={style.modal_close} onClick={()=>this.closeModal()}><i className="fa fa-times" aria-hidden="true"></i></span>
                        
                        <p>{this.state.send ? 'A new record has been created': ''}</p>
                    </div>
                </div>
                
            </div> 
        );
    }
}
}
import React from 'react';


import SectionMap from './SectionMap';

const Section = ({ match }) => {
    
    const { params: {layoutSlug ,id } } = match;
  
    return (
        < SectionMap name={id} layoutSlug={layoutSlug}/>
    );
  };

  export default Section;

import React, { Component } from 'react';
// import { NavLink } from 'react-router-dom';
import style from '../../../css/style.module.css';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { NavLink } from 'react-router-dom';


export default class CreateSectionMap extends Component {
  
  constructor(props){
    super(props);
    this.state = {
        modal: false,
        send: false,
        name: '',
        body_text: '',
        loaded: 0,
        redirectToHome: false,
        selectedFile: null, 
        type: ''
    }
}

 //Authentication
 componentDidMount() {

    // let bool = localStorage.getItem('x-access-token') && localStorage.getItem('x-access-token-expiration') > Date.now();
    let bool = localStorage.getItem('x-access-token');
    if(!bool){
        this.props.history.push('/login');
    }

}

submitForm(e){
    
    e.preventDefault();
    this.setState({modal: true});
    const formData = new FormData();
    if(this.state.type == 'image'){
        formData.append('photo', this.state.selectedFile);
    }else if(this.state.type == 'text'){
        formData.append('body_text', this.state.body_text);
    }
    formData.append('name', this.state.name.toLowerCase());
    formData.append('layoutSlug', this.props.name);
    formData.append('type', this.state.type.toLowerCase());

    axios.post('https://api.bamboocopter.net/api/createSection',formData, {
        onUploadProgress: ProgressEvent => {
          this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total*100),
          })
        },
      })
    .then(res => {
        this.resetForm();
        console.log(res.statusText);
        this.setState({send: true, selectedFile: null});
        this.props.history.push('/layout/'+this.props.name);
    }) 
  
}

resetForm(){
    document.getElementById("add_form").reset();
        this.setState({name: '', body: '', selectedFile: null, loaded: 0});
}

handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
    [name]: value
    });
}
onFileChange(e){
    this.setState({
      selectedFile: e.target.files[0],
      loaded: 0
    })
}


closeModal(){
    this.setState({modal: false})
}

render(){

    if(this.state.redirectToHome){
        return <Redirect to = {{ pathname: "/" }} />;
    }else{
        return(
            <div className={`${style.create}`}>
            
                <div className={`${style.container}`}>
                    <div className={style.header_nav}>
                        <NavLink to={`/layout/`+this.props.name}>
                            <span><i className="fas fa-arrow-left icon fa-2x"></i></span>
                        </NavLink>
                        <br/>
                        <h1 className={style.headline}>Add New Section</h1>
                    </div>
                    <div className={`${style.form}`}>
                        <div className={style.card}>
                            <form id="add_form" onSubmit={this.submitForm.bind(this)} method="POST">
                                <div className={style.row}>
                                    <input
                                    type="text"
                                    name="name"
                                    placeholder="Name"
                                    value={this.state.name}
                                    onChange={this.handleInputChange}
                                    required
                                    />
                                </div>
                                
                                <div className={style.row}>
                                    <label htmlFor="radio_text">Text</label>
                                    <input
                                    type="radio"
                                    name="type"
                                    id="radio_text"
                                    value="text"
                                    onChange={this.handleInputChange}
                                    /> 
                                </div>
                                <div className={style.row}>
                                    <label htmlFor="radio_image">Image</label>
                                     <input
                                    type="radio"
                                    name="type"
                                    id="radio_image"
                                    value="image"
                                    onChange={this.handleInputChange}
                                    />
                                </div>
                                {this.state.type == 'text'?
                                    <div className={style.row}>
                                        <textarea
                                        type="text"
                                        name="body_text"
                                        placeholder="body_text"
                                        value={this.state.body_text}
                                        onChange={this.handleInputChange}
                                        required
                                        />
                                    </div>
                                    :''
                                }

                                {this.state.type == 'image'?
                                    <div className={style.row}>
                                       <input type="file" onChange={this.onFileChange.bind(this)}/>
                                    </div>
                                    :''
                                }
                                
                                <div className={style.row}>
                                    <button type="submit" className={`${style.btn} ${style.btn_send}`}>Create</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

                <div className={style.modal} style={{display: this.state.modal ? 'flex' : 'none'}}>
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw" style={{color: '#fff', display: this.state.send ? 'none' : 'inline-block'}}></i><h1 style={{color: '#fff',textAlign: 'center', display: this.state.send ? 'none' : 'block-block'}}>{Math.round(this.state.loaded,2)}%</h1>
                </div>

                <div className={style.modal} style={{display: (this.state.send && this.state.modal) ? 'flex' : 'none'}}>
                    <div className={style.modal_content}>
                        <span className={style.modal_close} onClick={()=>this.closeModal()}><i className="fa fa-times" aria-hidden="true"></i></span>
                        
                        <p className={style.btn} onClick={()=>this.closeModal()}>{this.state.send ? 'Done': ''}</p>
                    </div>
                </div>
                
            </div> 
        );
    }
}
}
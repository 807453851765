import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';

//CSS
import style from '../../css/style.module.css';

export default class Header extends Component{
    constructor(props){
        super(props);
        this.state = {
            menuClick: false
        }
    }
    

    menuToggle(flag){
        this.setState({
            menuClick : !flag
        })
    }

    logOut(){
        localStorage.removeItem('x-access-token');
        window.location = '/login';
    }
    

    render(){

        // let bool = localStorage.getItem('x-access-token') && localStorage.getItem('x-access-token-expiration') > Date.now();
        let bool = localStorage.getItem('x-access-token');

        if(!bool){
            return null;
        }else{
            return (
                <div>
                    <span className={`${style.menu}`} style={{color: this.state.menuClick ? '#fff' : '#000' }} onClick={()=>(this.menuToggle(this.state.menuClick))}><i className="fas fa-bars icon"></i></span>
                    <header className={`${this.state.menuClick ? style.slideOut : ''}`}>
                        <div className={`${style.navigation}`}>
                            <ul>
                                <li><NavLink exact to="/" activeClassName={style.active}  onClick={()=>(this.menuToggle(this.state.menuClick))}>Albums</NavLink></li>
                                <li><NavLink exact to="/layouts" activeClassName={style.active}  onClick={()=>(this.menuToggle(this.state.menuClick))}>Web Layouts</NavLink></li>
                                <li><NavLink to="/" onClick={()=>(this.logOut())}>Logout</NavLink></li>
                            </ul>
                        </div>
                    </header>
                </div>
    
            );
        }

    }
}

import React, { Component } from 'react';
import style from '../../../css/style.module.css';
import axios from 'axios';
import { NavLink } from 'react-router-dom';


export default class Albums extends Component {
  
    constructor(props){
        super(props);
        this.state = {
            albums: []
        }
      }
      
    
      componentDidMount() {

        // let bool = localStorage.getItem('x-access-token') && localStorage.getItem('x-access-token-expiration') > Date.now();
        let bool = localStorage.getItem('x-access-token');
        if(bool){
            axios.get(`https://api.bamboocopter.net/api/getAlbums`)
            .then(res => {
                const albums = res.data.albums;
                // console.log(albums);
                this.setState({ 
                    albums
                });
            })
        }else{
            this.props.history.push('/login');
        }
    
      }

    render(){
      
        return(
            <div className={style.albums}>
                
                <div className={style.container}>
                    <div className={`${style.content}`}>
                        { this.state.albums.map(album => 
                            <div key={album.id} className={`${style.item}`}>
                                {/* <NavLink to={`/album/${album.name}`}> */}
                                <NavLink to={`/album/${album.name.split(' ').join('-')}`}>
                                    <div className={`${style.bg}`} style={{backgroundImage: `url(${album.cover_photo})`}}></div>
                                    <h4 className={`${style.title}`}>{album.name}</h4>
                                </NavLink>
                            </div>
                        )}

                            <div className={`${style.item}`}>
                                <NavLink to={`/album/create`}>
                                    <div className={`${style.bg} ${style.add}`}><span><i className="fas fa-plus icon fa-3x"></i></span></div>
                                    <h4 className={`${style.title}`}>Add New</h4>
                                </NavLink>
                            </div>
                    </div>
                </div>
            </div>
        );
    }
}
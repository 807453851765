import React, { Component } from 'react';
import style from '../../../css/style.module.css';
import axios from 'axios';

export default class RegisterPage extends Component {
  
    constructor(props) {
        super(props)
        this.state = {
          fname : '',
          lname : '',
          email : '',
          password: '',
          authError: false
        };
      }
    

    //Authentication
    componentDidMount() {

        let bool = localStorage.getItem('x-access-token') && localStorage.getItem('x-access-token-expiration') > Date.now();
        if(!bool){
            this.props.history.push('/login');
        }

    }


    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
        [name]: value
        });
    }

    onSubmit = (event) => {
        event.preventDefault();

        axios.post('https://api.bamboocopter.net/api/register', 
        {
            'email': this.state.email.toLowerCase(), 
            'password': this.state.password,
            'fname': this.state.fname.toLowerCase(),
            'lname': this.state.lname.toLowerCase(),
        })
        .then(res => {
            this.props.history.push('/login');
        }).catch(error => {
        });
    }

    render(){

        return (
            <div className={style.login}>
            <form onSubmit={this.onSubmit}>
                <h1>Register</h1>
                <input
                type="text"
                name="fname"
                placeholder="First name"
                value={this.state.fname}
                onChange={this.handleInputChange}
                required
                />
                <input
                type="text"
                name="lname"
                placeholder="Last name"
                value={this.state.lname}
                onChange={this.handleInputChange}
                required
                />
                <input
                type="email"
                name="email"
                placeholder="Enter email"
                value={this.state.email}
                onChange={this.handleInputChange}
                required
                />
                <input
                type="password"
                name="password"
                placeholder="Enter password"
                value={this.state.password}
                onChange={this.handleInputChange}
                required
                />
                <p style={{display: (this.state.authError) ? 'block' : 'none'}}>Invalid username or password!</p>
                
                
            <input type="submit" value="Submit" className={style.btn}/>
            </form>
            </div>   
        );
    }
}